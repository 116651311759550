<template>
  <div class="match-height align-items-start row student-dashboard">
    <div class="col-lg-12">
      <profile-status :profile_data="profile_data" />
    </div>
    <div class="col-12">
      <DemoMaterials />
    </div>
    <div class="col-12">
      <counts :data="counts" />
    </div>
    <div class="col-lg-5">
      <add-reference />
    </div>
    <div class="col-lg-6">
      <study-timeline />
    </div>
    <div class="col-lg-12">
      <assigned-staff :assigned_staff="assigned_staff" />
    </div>
    <div class="col-12">
      <study-abroad-journey />
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from "bootstrap-vue";
import "./style.css";

import ProfileStatus from "./helper/ProfileStatus.vue";
import DemoMaterials from "./helper/DemoMaterials.vue";
import AssignedStaff from "./helper/AssignedStaff.vue";
import StudyTimeline from "./helper/StudyTimeline.vue";
import StudyAbroadJourney from "./helper/StudyAbroadJourney.vue";
import StudentServices from "@/apiServices/StudentServices";
import AddReference from "./helper/AddReference.vue";
import Counts from "./helper/Counts.vue";

export default {
  components: {
    ProfileStatus,
    AssignedStaff,
    StudyTimeline,
    StudyAbroadJourney,
    AddReference,
    Counts,
    DemoMaterials,
    // DocumentStatus,
    // ApplicantsList,
    // Events,
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
  },

  data() {
    return {
      profile_data: {},
      assigned_staff: [],
      applications: {},
      counts: {},
    };
  },
  methods: {
    async GetHomePageData() {
      StudentServices.getHomePageData()
        .then((response) => {
          const data = response.data.data;
          this.profile_data = data.profile_data;
          this.applications = data.applications;
          this.assigned_staff = data.assigned_staff;

          this.counts = {
            course_shortlisted: data.course_shortlisted,
            applications: data.applications,
            offer_letters_received: data.offer_letters_received,
            awaiting_offer_letter: data.awaiting_offer_letter,
          };
        })
        .catch((err) => {
          console.log("Error getting home page data ", err);
        });
    },
  },
  beforeMount() {
    this.GetHomePageData();
  },
};
</script>

<style>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}
.primary-color {
  color: #00d09c;
}
</style>
